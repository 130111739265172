import React, { useEffect, useMemo, useState } from 'react'
import {
    Tabs,
    TabsHeader,
    TabsBody,
    Tab, 
    TabPanel,
    Avatar,
    Card
} from '@material-tailwind/react';
import ProgressBar from '../../commonComp/ProgressBar';
import AttentionSpan from './Charts/AttentionSpan';
import MotivationInitiation from './Charts/MotivationInitiation';
import TeacherPerformance from './Charts/TeacherPerformance';

import { apiBaseUrl } from "../../APIs/endpoints";
import { useNavigate } from 'react-router-dom';

const BehaviourRightSec = (props) => {
    const [activeTab, setActiveTab] = useState(props.selectedSubject);
    const [activeTabData, setActiveTabData] = useState(null); // To store active tab's data
    const sectionData = useMemo(() => props?.data || {}, [props?.data]);
    const [sortOrder, setSortOrder] = useState('bottom-to-top');

    const navigate = useNavigate();
    const { data, onSubjectChange, selectedSubject, fromDate, toDate, classInfo } = props;
    // console.log(props,"poerpojc")
    const [sortedStudents, setSortedStudents] = useState([]);
    // console.log(sectionData, "Current section data");
    // console.log(sectionData.individual_participation, "Individual Participation Data");
    // console.log(selectedSubject ,'selected subject');
    
    const sortStudents = (students, order) => {
        let sortedData = [...students];
        // Convert participation_score to float for proper sorting
        sortedData.forEach(student => {
            student.participation_score = parseFloat(student.participation_score);
        });
        
        if (order === 'top-to-bottom') {
            return sortedData.sort((a, b) => b.participation_score - a.participation_score);
        } else if (order === 'bottom-to-top') {
            return sortedData.sort((a, b) => a.participation_score - b.participation_score);
        }
        return sortedData;
    };
    
    // Your useEffect for sortedStudents remains unchanged
    useEffect(() => {
        if (sectionData[selectedSubject]?.individual_participation) {
            const sortedData = sortStudents(sectionData[selectedSubject].individual_participation, sortOrder);
            setSortedStudents(sortedData);
        } else {
            setSortedStudents([]);
        }
    }, [sectionData, selectedSubject, sortOrder]);

    const handleSelectChange = (event) => {
        const selectedValue = event.target.value;
        setSortOrder(selectedValue);
    };
    
    
    useEffect(() => {

    }, [data, selectedSubject, fromDate, toDate, classInfo]);

    function gotoStudent() {
        const navigationData = {
            fromDate: fromDate,
            toDate: toDate,
            grade: classInfo,
            subject: selectedSubject,
            section: sectionData[selectedSubject]?.section || 'A', 
        };
        // console.log("Navigating to student behaviour with data:", navigationData);
        navigate("/student-behaviour", { state: navigationData });
    }
    
    function calculateAverage(data) {
        if (!data || !Array.isArray(data) || data.length !== 3) {
        //   console.error("Data is not properly defined or doesn't have exactly 3 values", data);
          return 0; // or handle this case as needed
        }
        const total = data[0] + data[1] + data[2];
        const average = total / 3;
        return average.toFixed(2); 
       }
      
       const tabContent = (sectionData = {}) => {
        return (
            <div className='tab-content-container'>
                <div className='columns'>
                    <div className='column-1'>
                    <div className='graph-card' onClick={gotoStudent}>
                        <TeacherPerformance teacherData={sectionData.teacher_data} />
                    </div>

    
                        <div className='row'>
                        <div className='graph-card' onClick={gotoStudent}>
                        <div className='card-name ml-4 flex items-center'>
                                    <img src='/images/Students_icon.png' alt='user' className='mr-2' />
                                    <span>Attention Span</span>
                                </div>
                                <div className='text-center  ml-10'>
                                    <div className='chart-value flex flex-col items-center justify-center  border-2 border-[#656565] w-3/5 mx-auto rounded-lg'>
                                        <div className='font-bold text-[#2B3674]'>
                                            {calculateAverage(sectionData.attention_span)} min
                                        </div>
                                        <div className='text-sm text-[#2B3674]'>Avg Time</div>
                                    </div>
                                </div>
                                <AttentionSpan chartData={sectionData.attention_span} />
                            </div>                           
                            <div className='graph-card' onClick={gotoStudent}>
                                <div className='card-name ml-4 flex items-center'>
                                    <img src='/images/Students_icon.png' alt='user' className='mr-2' />
                                    <span>Emotional State</span>
                                </div>
                                <div className='emotional-emojis text-md'>
                                    {/* Emotional States */}
                                    <div className='emotions'>
                                        <div className='icon'>
                                            <span>😄</span>
                                            <span>Happy</span>
                                        </div>
                                        <div className='number'>{sectionData?.emotional_state?.joy}</div>
                                    </div>
                                    <div className='emotions'>
                                        <div className='icon'>
                                            <span>🙂</span>
                                            <span>Neutral</span>
                                        </div>
                                        <div className='number'>{sectionData?.emotional_state?.happy}</div>
                                    </div>
                                    <div className='emotions'>
                                        <div className='icon'>
                                            <span>😐</span>
                                            <span>Surprise</span>
                                        </div>
                                        <div className='number'>{sectionData?.emotional_state?.calm}</div>
                                    </div>
                                    <div className='emotions'>
                                        <div className='icon'>
                                            <span>🙁</span>
                                            <span>Least Intrested</span>
                                        </div>
                                        <div className='number'>{sectionData?.emotional_state?.sad}</div>
                                    </div>
                                    <div className='emotions'>
                                        <div className='icon'>
                                            <span>😟</span>
                                            <span>Not Intrested</span>
                                        </div>
                                        <div className='number'>{sectionData?.emotional_state?.["very sad"]}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div className='column-2'>
                        <div className='graph-card ' onClick={gotoStudent}>
                                <div className='card-name ml-4 flex items-center'>
                                    <img src='/images/Students_icon.png' alt='user' className='mr-2' />
                                    <span>Class Participation</span>
                                </div>
                            <div className='overall-percentage ml-4'>
                                <span style={{ fontWeight: 'bold', fontSize: '1.5rem', color: 'black', marginTop:"1rem" }}>
                                    {sectionData.participation}%
                                </span>
                                <span style={{ color: 'green' }}>
                                    &#x279A;
                                </span>            
                            </div>
                            <div className='overall ml-4'>Overall</div>
                        </div>
    
                        <div className='graph-card'onClick={gotoStudent}>
                            <div className='card-name ml-4 flex items-center'>
                                    <img src='/images/Students_icon.png' alt='user' className='mr-2' />
                                    <span>Motivation & Initiation</span>
                                </div>
                            <MotivationInitiation chartData={sectionData?.motivation || []} />
                        </div>
    
                        <div className='stretch-card' style={{paddingBottom:'2.5rem'}}> 
                            <div className='card-header'>
                                <div className='card-title'>
                                        <span className='card-name ml-4'>Student Board</span>
                                </div>
                             </div>
                                <div className='dropdown ml-4 mb-4' style={{marginTop:"-1.5rem"}}> 
                                    <select
                                        className='form-select text-gray-400'
                                        onChange={(e) => handleSelectChange(e)}
                                    >
                                        <option value='bottom-to-top'>Bottom to Top</option>
                                        <option value='top-to-bottom'>Top to Bottom</option>
                                    </select>
                                </div>

                            <div className='student-list ml-4'>
                             {sortedStudents.map(student => (
                                    <div className='student-item' key={student.student_id}>
                                        <div className='flex justify-between items-center w-full'>
                                            <span>{student.student_name}</span>
                                            <span>{student.participation_score}%</span>
                                        </div>
                                        <div className='progress-bar'>
                                            <div className='progress' style={{ width: `${student.participation_score}%` }} />
                                        </div>
                                    </div>
                                ))}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    };
    

    const tabSectionData = Object.keys(sectionData).map(subject => ({
        label: subject.toUpperCase(),
        value: subject,
        desc: tabContent(sectionData[subject]),
    }));

    useEffect(() => {
        if (!selectedSubject && tabSectionData.length > 0) {
            onSubjectChange(tabSectionData[0].value);
        }
    }, [selectedSubject, tabSectionData, onSubjectChange]);

    useEffect(() => {
        // Find the active tab's data and set it
        const selectedTabData = tabSectionData.find(tab => tab.value === selectedSubject);
        setActiveTabData(selectedTabData);
        setActiveTab(selectedSubject);
    }, [selectedSubject, tabSectionData]);

    // console.log('Active Tab Data:', activeTabData);
    // console.log(selectedSubject,"ppppppppppppppppppppppppppppppppp")
    return (
        <div style={{marginTop:'-4%',backgroundColor:'#F8F8F8'}}>
            <Tabs value={activeTab}>
                <TabsHeader
                    className='rounded-none border-b border-blue-gray-50 bg-transparent p-0 mt-6'
                    indicatorProps={{
                        className:
                            'bg-transparent shadow-none rounded-none',
                    }}
                >
                    {tabSectionData?.map(({ label, value }) => (
                        <Tab
                            key={value}
                            value={value}
                            onClick={() => onSubjectChange(value)}
                            className={selectedSubject === value ? 'tab-active' : 'hanuman'}
                        >
                            {label}
                        </Tab>
                    ))}
                </TabsHeader>
                <TabsBody>
                    {tabSectionData?.map(({ value, desc }) => (
                        <TabPanel key={value} value={value}>
                            {selectedSubject === value ? desc : null}
                            </TabPanel>
                    ))}
                </TabsBody>
            </Tabs>
        </div>
    )
}

export default BehaviourRightSec;