import React from "react";
import './menu.scss';
import { MultiLevelSidebar } from './SideBar';

const Menu = () => {
    return (
        <div className="menu-container">
            <div className="transpoze-logo-container">
                <div className="transpoze-logo">
                    <img src="/images/tpz-logo-orange.png" alt="Logo" />
                </div>
                <div className="transpoze-title">Transpoze</div>
            </div>
            <div className="sidebar-container">
                <MultiLevelSidebar />
            </div>
        </div>
    );
}

export default Menu;