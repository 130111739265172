import React from 'react';
import ReactApexChart from "react-apexcharts";

const AttentionSpan = ({ chartData = [0, 0, 0] }) => {
    const options = {
        chart: {
            type: 'bar',
            height: 200,
            toolbar: {
                show: false
            }
        },
        xaxis: {
            type: 'category',
            categories: ['0-15min', '15-30min', '30-45min']
        },
        yaxis: {
            tooltip: {
                enabled: false
            },
            tickAmount: 3,
            show: true,
            floating: false,
            axisBorder: {
                show: true,
                color: '#FFCECE',
                offsetX: 0,
                offsetY: 0
            },
            axisTicks: {
                show: false
            },
            labels: {
                show: true
            },
            min: 0,
            max: 15,
        },
        grid: {
            show: false, 
        },
        fill: {
            type: 'gradient',
            gradient: {
                shade: 'light',
                type: 'vertical',
                shadeIntensity: 0.25,
                gradientToColors: ['#FFB07A'],
                inverseColors: false,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 100],
            },
        },
        plotOptions: {
            bar: {
                columnWidth: '40%',
                borderRadius: 10
            }
        },
        annotations: {
            yaxis: [
                {
                    y: 0,
                    borderColor: '#FFCECE',
                    strokeDashArray: 0,
                    label: {
                        borderColor: '#FFCECE',
                        style: {
                            color: '#FFCECE',
                            background: '#FFCECE',
                        },
                    }
                }
            ]
        }
    };

    if (!chartData || chartData.length === 0) {
        return <div>No data available</div>;
    }

    const series = [{ data: chartData }]; 

    try {
        return (
            <div>
                <ReactApexChart options={options} series={series} type="bar" height={250} />
            </div>
        );
    } catch (error) {
        console.error('Error rendering chart:', error);
        return <div>Error rendering chart</div>;
    }
};

export default AttentionSpan;
