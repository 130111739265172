import { Card, CardBody, Typography, Avatar, Spinner } from "@material-tailwind/react";
import { MdOutlineShowChart } from "react-icons/md";
import { getApiResponse } from "../APIs/getApiResponse";
import { endpoints, apiBaseUrl } from "../APIs/endpoints";
import { useState, useEffect } from "react";
import DatePicker from "../commonComp/DateRangePicker";
import './DefaultTable.css'; // Importing the CSS file
import { handleError } from "../APIs/apiUtils";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useLocation } from "react-router-dom";
import { Breadcrumbs } from "@material-tailwind/react";

const TABLE_HEAD = ["Student Name",  "Attention Span", "Motivation & Initiation", "Attendance & Punctuality"];

function DefaultTable() {
    const [studentsData, setStudentsData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [dateRange, setDateRange] = useState({});
    const [gradesAndSections, setGradesAndSections] = useState([]); // To store grades and sections
    const [sectionsList, setSectionsList] = useState([]); // To store sections
    const [selectedClass, setSelectedClass] = useState('');
    const [selectedSection, setSelectedSection] = useState('');
    const [selectedSubject, setSelectedSubject] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [subjects, setSubjects] = useState([]); // Subjects based on selected grade

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const { state } = location;
        if (state) {
            // console.log("Received state:", state);
            setDateRange({
                startDate: state.fromDate,
                endDate: state.toDate
            });
            setSelectedClass(state.grade || '');
             setSelectedSubject(state.subject || '');
            
            // console.log("From Date:", state.fromDate);
            // console.log("To Date:", state.toDate);
            // console.log("Class:", state.grade);
            
            // console.log("Subject:", state.subject);
        }
    }, [location]);

    useEffect(() => {
        // Fetch and parse grades and subjects from localStorage
        const storedData = JSON.parse(localStorage.getItem('subjects')) || [];
        if (selectedClass) {
          const gradeData = storedData.find((item) => item.grade === selectedClass);
          setSubjects(gradeData?.subjects || []); // Update subjects for selected grade
        } else {
          setSubjects([]); // Clear subjects when no grade is selected
        }
      }, [selectedClass]);

    const getOverviewData = async () => {
        setIsLoading(true);
        try {
            const urlParams = new URLSearchParams();
            if (dateRange.startDate) urlParams.append('fromDate', dateRange.startDate);
            if (dateRange.endDate) urlParams.append('toDate', dateRange.endDate);
            if (selectedClass) urlParams.append('grade', selectedClass);
            if (selectedSubject) urlParams.append('subject', selectedSubject);
            if (selectedSection) urlParams.append('section',selectedSection);

            // console.log("API request params:", urlParams.toString());

            const resp = await getApiResponse(`${endpoints.STUDENTSBEHAVIOUR}?${urlParams.toString()}`);
            // console.log("API response:", resp);
            setStudentsData(resp?.data || []);
            setFilteredData(resp?.data || []);
            // Update subjects list
            if(resp?.data?.subjects){
                setSubjects(['All', ...resp?.data?.subjects]);
            }
        } catch (error) {
            const { errorMessage } = handleError(error);
            // console.error("API error:", errorMessage);
            navigate('/error', { 
                state: { errorMessage }
            });
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        // Check if the necessary parameters are set before fetching data
        const isValidParams = dateRange.startDate && dateRange.endDate && selectedClass && selectedSubject && selectedSection;
    
        if (isValidParams) {
            getOverviewData();
        } else {
            // Optionally reset students data when parameters are invalid
            setStudentsData([]);
            setFilteredData([]);
        }
    }, [dateRange, selectedClass, selectedSection, selectedSubject]);
    

    const getDateRange = (range) => {
        // console.log("Date range changed:", range);
        setDateRange(range);
    };

    const getGradesAndSections = async () => {
        const schoolName = localStorage.getItem('school_name');
        if (!schoolName) return;

        const url = `${endpoints.GRADES}?school=${encodeURIComponent(schoolName)}`;
        try {
            const resp = await getApiResponse(url);
            if (resp?.data) {
                setGradesAndSections(resp.data); // Set the whole response
            }
        } catch (error) {
            console.error("Error fetching grades and sections data:", error);
        }
    };

    
    useEffect(() => {
        if (selectedClass) {  // Make sure you are using 'selectedClass' instead of 'selectedGrade'
            const filteredSections = gradesAndSections
                .filter(item => item.grade === selectedClass)  // Use 'selectedClass' for filtering
                .map(item => item.section);  // Extract sections for the selected grade
            setSectionsList(filteredSections);
            setSelectedSection('');  // Reset section when grade changes
        }
    }, [selectedClass, gradesAndSections]);
    
    useEffect(() => {
        getGradesAndSections();
    }, []);
    
    return (
        <div className="default-table-container">
            <ToastContainer />
            <Breadcrumbs className="mb-4">
                <a href="/dashboard" className="opacity-60">
                    Dashboard
                </a>
                <a href={`/behaviour/${selectedClass || ''}`} className="opacity-60" onClick={(e) => {
                    e.preventDefault();
                    navigate(`/behaviour/${selectedClass || ''}`, {
                        state: {
                            fromDate: dateRange.startDate,
                            toDate: dateRange.endDate,
                            subject: selectedSubject,
                            class: selectedClass,
                            section:selectedSection
                        }
                    });
                }}>
                    Behaviour
                </a>
                <a href="#">Behaviour List</a>
            </Breadcrumbs>
                <div className='dashboard-filters'>
                    <div className='left-filters flex items-center'>
                        <div className='filter-group mr-4'>
                            <DatePicker 
                                getDateRange={getDateRange}
                                initialDateRange={dateRange}
                                initiallySelected={true}
                            />
                        </div>
                        <div className="filter-group mr-4">
                            <label className="filter-label mr-2">Class:</label>
                            <select 
                                value={selectedClass} 
                                onChange={(e) => {
                                    // console.log("Class changed:", e.target.value);
                                    setSelectedClass(e.target.value);
                                }} 
                                className="filter-select"
                            >
                                <option value="">Select Class</option>
                                {/* Dynamically populating classes from gradesAndSections */}
                                {gradesAndSections.map(({ grade }) => (
                                    <option key={grade} value={grade}>
                                        {typeof grade === 'string' ? grade.charAt(0).toUpperCase() + grade.slice(1) : grade}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="filter-group mr-4">
                            <label className="filter-label mr-2">Section:</label>
                            <select 
                                value={selectedSection} 
                                onChange={(e) => {
                                    // console.log("Section changed:", e.target.value);
                                    setSelectedSection(e.target.value);
                                }} 
                                className="filter-select"
                            >
                                <option value="">Select Section</option>
                                {/* Dynamically populating sections based on selectedClass */}
                                {sectionsList.map((section) => (
                                    <option key={section} value={section}>
                                        {section}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="filter-group">
                            <label className="filter-label mr-2">Subject:</label>
                            <select
                                className="dropdown-select border-none"
                                value=""
                                // onChange={(e) => console.log('Selected subject:', e.target.value)}
                                >
                                <option disabled value="">
                                    Select Subject
                                </option>
                                {subjects.map((subject, index) => (
                                    <option key={index} value={subject}>
                                    {subject.charAt(0).toUpperCase() + subject.slice(1)}
                                    </option>
                                ))}
                                </select>
                        </div>
                    </div>
                </div>
            <Card className="h-full w-full overflow-scroll">
               <CardBody className="table-body">
                    <Typography variant="h5" color="blue-gray" className="mb-2" style={{marginLeft:'6.5rem'}}>
                        All Students
                    </Typography>
                    {isLoading ? (
                        <div className="flex justify-center items-center h-64">
                            <Spinner className="h-12 w-12" color="blue" />
                        </div>
                    ) : (
                        <table className="w-full min-w-max table-auto text-center">
                            <thead>
                                <tr>
                                    {TABLE_HEAD.map((head) => (
                                        <th key={head} className="table-header-cell p-4 text-center">
                                            <Typography variant="small" className="font-normal  leading-none opacity-70">
                                                {head}
                                            </Typography>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {filteredData.map(({ student_name, student_photo, attention_span, motivation, attendance }, index) => {
                                    const isLast = index === filteredData.length - 1;
                                    const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

                                    return (
                                        <tr key={student_name}>
                                            <td className={classes}>
                                            <div className="flex items-center flex flex-col justify-center " >
                                                    <Avatar src={`${apiBaseUrl}${student_photo}`} alt="avatar" size="sm" />
                                                    <div className="flex flex-col text-left">
                                                        <Typography variant="small" color="blue-gray" className="font-normal text-left">
                                                            {student_name}
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className={classes + " text-center"}>
                                                <div>{attention_span} <MdOutlineShowChart style={{ "display": "inline" }} /></div>
                                            </td>
                                            <td className={classes + " text-center"}>
                                                <div>{motivation} <MdOutlineShowChart style={{ "display": "inline" }} /></div>
                                            </td>
                                            <td className={classes + " text-center"}>
                                                <div>{attendance} <MdOutlineShowChart style={{ "display": "inline" }} /></div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    )}
                </CardBody>

            </Card>
            <br />
        </div>
    );
}

export default DefaultTable;
