import React, { useEffect, useState, useCallback } from "react";
import './attendance.scss';
import { Avatar, Tooltip } from "@material-tailwind/react";
import { getApiResponse } from "../../APIs/getApiResponse";
import { endpoints } from "../../APIs/endpoints";
import { HiOutlineDesktopComputer, HiOutlineUsers } from "react-icons/hi";
import { FiUserCheck } from "react-icons/fi";
import Pagination from '../../commonComp/Pagination';
import { apiBaseUrl } from "../../APIs/endpoints";
import { useNavigate } from "react-router-dom";

const Index = () => {
    const formatDate = (date) => {
        const d = new Date(date);
        const month = (d.getMonth() + 1).toString().padStart(2, '0');
        const day = d.getDate().toString().padStart(2, '0');
        const year = d.getFullYear();
       // return `${month}/${day}/${year}`;
        return `${day}/${month}/${year}`;
    };

const getYesterdayDate = () => {
    const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
    return formatDate(yesterday);
};

    const [attendanceData, setAttendanceData] = useState({});
    const [classesList, setClassesList] = useState(['All']);
    const [gradesAndSections, setGradesAndSections] = useState([]); 
    const [selectedGrade, setSelectedGrade] = useState('');
    const [sectionsList, setSectionsList] = useState([]);
    const [selectedDate, setSelectedDate] = useState(getYesterdayDate());
    const [subjects, setSubjects] = useState([]); // Subjects based on selected grade


    const [filterObj, setFilterObj] = useState({
         grade: '', 
         section: '',
        subject: '',
        date: selectedDate 
    });

    const [studentsList, setStudentsList] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('access_token'); 
    
        if (!token) { 
          navigate('/login'); 
        }
      }, [navigate]); 
    
      const getAttendance = async () => {
        let attendanceUrl = endpoints.ATTENDANCE;
        if (Object.keys(filterObj).length) {
            const { date, grade, section, subject } = filterObj;
            let params = '';
            if (date) params += `date=${date}&`;
            if (grade && grade !== 'All') params += `class=${grade}&`;
            if (section) params += `section=${section}&`;
            if (subject && subject !== 'All') params += `subject=${subject}&`;
            attendanceUrl = attendanceUrl + '?' + params.slice(0, -1);
        }

        try {
            const resp = await getApiResponse(attendanceUrl);
            setAttendanceData(resp?.data || {
                attendance: { value: 0, increased: false, change: 0 },
                absence: { value: 0, increased: false, change: 0 },
                late_comers: { value: 0, increased: false, change: 0 },
                students: []
            });

            setClassesList(['All', ...Object.keys(resp?.data?.classes || {})]);
            setStudentsList(resp?.data?.students || []);
        } catch (error) {
            console.error("Error fetching attendance data:", error);
            setAttendanceData({
                attendance: { value: 0, increased: false, change: 0 },
                absence: { value: 0, increased: false, change: 0 },
                late_comers: { value: 0, increased: false, change: 0 },
                students: []
            });
            setStudentsList([]);
        }
    };

    const getGradesAndSections = async () => {
        const schoolName = localStorage.getItem('school_name');
        if (!schoolName) return;

        const url = `${endpoints.GRADES}?school=${encodeURIComponent(schoolName)}`;
        try {
            const resp = await getApiResponse(url);
            if (resp?.data) {
                setGradesAndSections(resp.data);

                // Set first grade as default, only if selectedGrade is not already set
                if (!selectedGrade && resp.data.length > 0) {
                    setSelectedGrade(resp.data[0].grade); // Set the first grade as default
                    setFilterObj(prev => ({ ...prev, grade: resp.data[0].grade })); // Update filter object
                }
            }
        } catch (error) {
            console.error("Error fetching grades and sections data:", error);
        }
    };

    
    
    useEffect(() => {
        getGradesAndSections();
    }, []);

    useEffect(() => {
        getAttendance();
    }, [filterObj]); 

    

        useEffect(() => {
            if (selectedGrade) {
                const filteredSections = gradesAndSections
                    .filter(item => item.grade === selectedGrade)
                    .map(item => item.section); 
                setSectionsList(filteredSections);
                setFilterObj(prev => ({ ...prev, section: filteredSections[0] || '' })); // Set first section as default
            }
        }, [selectedGrade, gradesAndSections]);

    const getDate = useCallback((date) => {
        const formattedDate = new Date(date).toLocaleDateString('en-GB'); 
        
        setSelectedDate(formattedDate);
        setFilterObj(prev => ({
            ...prev,
            date: formattedDate 
        }));
    }, []);


    useEffect(() => {
        // Fetch and parse grades and subjects from localStorage
        const storedData = JSON.parse(localStorage.getItem('subjects')) || [];
        if (selectedGrade) {
          const gradeData = storedData.find((item) => item.grade === selectedGrade);
          setSubjects(gradeData?.subjects || []); // Update subjects for selected grade
        } else {
          setSubjects([]); // Clear subjects when no grade is selected
        }
      }, [selectedGrade]);
    

    const getSelectedClass = e => {
        const val = e.target.value;
        setFilterObj((prev) => ({...prev, className: val, section: ''}));
        if (val !== 'All') {
            setSectionsList(attendanceData?.classes?.[val] || []);
        } else {
            setSectionsList([]);
        }
    }

    const getSelectedSection = e => {
        setFilterObj((prev) => ({...prev, section: e.target.value}));
    }

    const getSelectedSubject = e => {
        setFilterObj((prev) => ({...prev, subject: e.target.value}));
    }

    const getRecords = data => {
        setStudentsList(data);
    };

    const valueMap = {P: 'Present', A: 'Absent', NotRecorded: 'Absent'}

    const arrowMark = (increased = true) => <span className={increased ? 'upArrow' : 'downArrow'}>&#x279D;</span>;
    return (
        <div className="attendance-container">
            <h3 className="top-heading">Attendance</h3>
            <div className="filter-section" >
            <div className="filter-section-datefield">
                <input
                    type="date"
                    value={selectedDate.split('/').reverse().join('-')}
                    onChange={(e) => getDate(e.target.value)}
                    className="border-2 border-gray-300 rounded-md p-2 focus:border-[#EF805D] focus:outline-none 
                            bg-white text-gray-700 placeholder-gray-400 transition duration-200 
                            hover:shadow-lg hover:border-[#EF805D]"
                    placeholder="Select a date"
                />
                </div>
                <div className="filter-section-dropdown">
                <select
                    className="dropdown-select border-none"
                    value={selectedGrade}
                    onChange={e => {
                        const grade = e.target.value;
                        setSelectedGrade(grade); // Update selected grade
                        setFilterObj(prev => ({ ...prev, grade })); // Update filter object
                    }}>
                    <option disabled value=''>Select Grade</option>
                    {gradesAndSections.map((item, index) => (
                        <option key={index} value={item.grade}>{item.grade}</option>
                    ))}
                </select>
            </div>
            <div className="filter-section-dropdown">
                <select
                    className="dropdown-select border-none"
                    value={filterObj.section || ''}
                    onChange={e => setFilterObj(prev => ({ ...prev, section: e.target.value }))}>
                    <option disabled value=''>Select Section</option>
                    {sectionsList.map((section, index) => (
                        <option key={index} value={section}>{section}</option>
                    ))}
                </select>
            </div>
            <div className="filter-section-dropdown">
                <select
                className="dropdown-select border-none"
                value=""
                onChange={(e) => console.log('Selected subject:', e.target.value)}
                >
                <option disabled value="">
                    Select Subject
                </option>
                {subjects.map((subject, index) => (
                    <option key={index} value={subject}>
                    {subject.charAt(0).toUpperCase() + subject.slice(1)}
                    </option>
                ))}
                </select>
            </div>
            </div>
            <div className="attendance-status-container">
                <div className="attendance-content">
                    <div className="section-content">
                        <div className="section-icon">
                            <HiOutlineUsers size='50'/>
                        </div>
                        <div className="section-data">
                            <div className="section-title">Total Attendance</div>
                            <div className="section-score">{attendanceData?.attendance?.value}%</div>
                            <div className="section-growth">
                                {arrowMark(attendanceData?.attendance?.increased)}
                                <span>{attendanceData?.attendance?.change}%</span> this month
                            </div>
                        </div>
                    </div>
                    <div className="section-content">
                        <div className="section-icon">
                            <FiUserCheck size='50'/> 
                        </div>
                        <div className="section-data">
                            <div className="section-title">Total Absence</div>
                            <div className="section-score">{attendanceData?.absence?.value}%</div>
                            <div className="section-growth">
                                {arrowMark(attendanceData?.absence?.increased)}
                                <span>{attendanceData?.absence?.change}%</span> this month
                            </div>
                        </div>
                    </div>
                    <div className="section-content">
                        <div className="section-icon">
                            <HiOutlineDesktopComputer size="50" />
                        </div>
                        <div className="section-data">
                            <div className="section-title">Late Comers</div>
                            <div className="section-score">{attendanceData.late_comers?.value}</div>
                            <div className="section-growth">
                                {arrowMark(attendanceData?.late_comers?.increased)}
                                <span>{attendanceData.late_comers?.change}%</span> this month
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="attendance-list-section" >
                <h4 className="attendance-list-section-heading">All Students</h4>
                <table className="attendance-table-content" >
                    <thead className="attendance-table-header">
                        <tr className="table-row">
                            <th>Student Name</th>
                            <th><div className="header-content">
                                    <div className="header-icon">
                                        <img src="/images/list_check.png" alt="icon"/>
                                    </div>
                                    <div className="header-text">Manual Attendance</div>
                                </div>
                            </th>
                            <th>
                                <div className="header-content">
                                    <div className="header-icon">
                                        <img src="/images/cc_camera.png" alt="icon"/>
                                    </div>
                                    <div className="header-text">Digital Attendance</div>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {studentsList?.map(stu => {
                            return <tr className="attendance-table-row" key={stu.id}>
                                <td>
                                    <div className="user-name">
                                        <span className="user-icon">
                                            <Tooltip content={<img src={`${apiBaseUrl}${stu?.student_photo}`} alt="student" style={{maxWidth: '400px', maxHeight: '400px', }} />} placement="right-end">
                                                <Avatar src={`${apiBaseUrl}${stu?.student_photo}`} alt="student-icon" />
                                            </Tooltip>
                                        </span>
                                        <span>{stu.name}</span>
                                    </div>
                                </td>
                                <td>
                                    <span className="manual-attendance-line"></span>
                                </td>
                                <td>
                                    <span className={stu.attendance === 'P' ? 'stu-present' : stu.attendance === 'A' ? 'stu-absent' : 'stu-default'}>{valueMap[stu.attendance]}</span>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
                {attendanceData?.students?.length > 0 && <Pagination data={attendanceData?.students} getRecords={getRecords} itemsPerPage={10} />}
            </div>            
        </div>
    )
}

export default Index;
