import React, { useEffect, useState } from 'react';
import './charts.scss';
import Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
highchartsMore(Highcharts);

const MotivationInitiation = (props) => {
    const [options, setOptions] = useState({
        chart: {
            type: 'gauge',
            plotBackgroundColor: null,
            backgroundColor: 'transparent',
            plotBackgroundImage: null,
            plotBorderWidth: 0,
            plotShadow: false,
            height: '75%'
        },
        credits: {
            enabled: false
        },
        title: {
            text: ''
        },
        pane: {
            startAngle: -120,
            endAngle: 120,
            background: null,
            center: ['50%', '65%'],
            size: '100%'
        },
        yAxis: {
            min: 0,
            max: 100,
            tickPosition: 'inside',
            tickLength: '10%',
            tickWidth: 0.2,
            minorTickInterval: null,
            labels: {
                distance: '-24.5rem',
                style: {
                    fontSize: '0.675rem',
                    fontWeight: 'bold'
                },
                step: 1
            },
            tickInterval: 20,
            lineWidth: 0,
            plotBands: [
                { from: 0, to: 20, color: '#FFD495', thickness: '15%' },
                { from: 20, to: 40, color: '#FCD34D', thickness: '15%' },
                { from: 40, to: 60, color: '#FBBF24', thickness: '15%' },
                { from: 60, to: 80, color: '#F59E0B', thickness: '15%' },
                { from: 80, to: 100, color: '#34D399', thickness: '15%' }
            ]
        },
        series: [
            {
                name: 'Motivation',
                data: [0], // Initialize with default value
                dial: {
                    radius: '80%',
                    backgroundColor: '#F08357',
                    baseWidth: 3,
                    topWidth: 1,
                    baseLength: '0%',
                    rearLength: '30%'
                },
                pivot: {
                    backgroundColor: '#F86F6F',
                    radius: 10
                },
                dataLabels: {
                    enabled: false
                }
            }
        ]
    });

    const chartsData = props.chartData || 0;

    // Update the chart options when chartData changes
    useEffect(() => {
        setOptions((prevOptions) => ({
            ...prevOptions,
            series: [
                {
                    ...prevOptions.series[0],
                    data: [chartsData] // Update the needle position
                }
            ]
        }));
    }, [chartsData]);

    return (
        <div className="charts-container" style={{ marginTop: '-2rem' }}>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
                {...props}
            />
            <div
                className="chart-value flex flex-col items-center justify-center"
                style={{ marginTop: '-4rem' }}
            >
                <span
                    className="text-xl text-black font-bold text-center"
                    style={{ fontSize: 'clamp(0.8rem, 1vw, 1.2rem)' }}
                >
                    {chartsData ? `${chartsData}%` : '0%'}
                </span>
                <span
                    className="chart-value-text text-center"
                    style={{ fontSize: 'clamp(0.8rem, 0.9vw, 1rem)' }}
                >
                    Overall
                </span>
            </div>
        </div>
    );
};

export default MotivationInitiation;
